import cmsHubMembershipApi from "api/membershipApi";
import TableContainer from "components/Common/TableContainer";
import { catchAsync, formatDate, formatWithKoreanAmPm } from "helpers/general";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";

interface Props {
  isOpen: boolean;
  id: number;
  onClose: () => void;
}

const ModalHistory = (props: Props) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<Array<any>>([]);

  const columns = [
    {
      Header: t("media.STT"),
      accessor: "id",
      sortable: false,
      thWidth:50,
      thClassName: "text-center",
      Cell: ({ row }: any) => <div className="text-center">{rows?.length - (row?.index || 0)}</div>,
    },
    {
      Header: t("membership.membership"),
      accessor: "name",
      sortable: false,
      thWidth:80,
    },
    {
      Header: t("membership.payment_date"),
      accessor: "scheduled_cancel_at",
      sortable: false,
      thWidth:150,
      thClassName: "text-center",
      Cell: ({ row }: any) => {
        return (
          <div>
            {row?.original?.scheduled_cancel_at ? (
              <div>{formatDate(row?.original?.scheduled_cancel_at,"YYYY.MM.DD")} {t('membership.scheduled_for_termination')}</div>
            ) : formatDate(row?.original?.created_at,"YYYY.MM.DD HH:mm:ss")}
          </div>
        );
      },
    },
    {
      Header: t("membership.memo"),
      accessor: "note",
      sortable: false,
      Cell: ({ row }: any) => {
        return (
          <div style={{ wordBreak: "break-word" }}>
            {row?.original?.note || "-"}
          </div>
        );
      },
    },
  ];

  const getHistory = async () => {
    const [err, result] = await catchAsync(
      cmsHubMembershipApi.cmshubCompanyPlanHistory(props.id)
    );

    if (err) {
      console.log(err);
      return;
    }
    setRows(result?.data);
  };
  useEffect(() => {
    if (props.id && props.isOpen) {
      getHistory();
    } else {
      setRows([]);
    }
  }, [props.id, props.isOpen]);

  return (
    <Modal
      isOpen={props.isOpen}
      centered={true}
      scrollable={false}
      style={{ width: "606px",maxWidth:"606px" }}
    >
      <div className="d-flex justify-content-between pt-3 pb-2 px-3 items-center">
        <div></div>
        <div style={{ fontSize: "18px", fontWeight: "700" }}>
          {t("membership.membership_payment_details")}
        </div>
        <div onClick={() => props.onClose()}>
          <i
            className="ri-close-line"
            style={{ fontSize: "20px", cursor: "pointer" }}
          ></i>
        </div>
      </div>
      <ModalBody>
        <div style={{ minHeight: "500px" }}>
          <TableContainer
            columns={columns}
            data={rows || []}
            customPageSize={rows?.length}
            customPageIndex={rows?.length}
            totalRecords={rows?.length}
            customPageCount={1}
            className="custom-header-css"
            divClass="table-responsive table-card h-100"
            tableClass="align-middle"
            theadClass="table-light align-middle"
            isShowPagination={false}
            messageEmpty={t("membership.there_is_no_history")}
            heightEmpty="437px"
          />
        </div>
        <div className="hstack gap-2 mt-3 justify-content-center px-5">
          <button
            className="btn btn-soft-secondary fs-14"
            type="button"
            color="light"
            style={{ width: "150px" }}
            onClick={() => props?.onClose()}
          >
            {t("cms_media.cancellation")}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalHistory;
