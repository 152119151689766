import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import classnames from "classnames";
//
import "react-toastify/dist/ReactToastify.css";
import useQueryParams from "components/Hooks/useQueryParams";
import TableContainer from "../../../components/Common/TableContainer";
import { catchAsync, formatNumber, formatWithKoreanAmPm } from "helpers/general";
import { IPagination } from "api/types/general";
import DatePickerCustom from "components/Common/DatePickerCustom";
import { IresMediaCmsHub } from "api/types/_cmsHub";
import { MEMBERSHIP_STATUS, TYPE_TRANSMISSION_FORMAT_MEMBERSHIP_OPTIONS } from "helpers/constans";
import CardSummary from "pages/Media/Components/CardSummary";
import ModalUpdateMember from "./ModalUpdate";
import ModalHistory from "./ModalHistory";
import cmsHubMembershipApi from "api/membershipApi";
import DropdownOption from "components/Common/DropdownOption";
import { ROUTES } from "routes/allRoutes";
import ModalHistoryTrafic from "./ModalHistoryTrafic";

const MemberTab = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 10,
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    plan_code: "",
  };
  const navigate = useNavigate();
  const [loadingSummaries, setLoadingSummaries] = useState(false);
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const defaultRangeDate = [
    moment(queryParams?.start_date || "", "Y-MM-DD").toDate(),
    moment(queryParams?.end_date || "", "Y-MM-DD").toDate(),
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;
  const [summaries, setSummaries] = useState<any>();
  const [keyword, setKeyword] = useState<string>("");
  const [rows, setRows] = useState<Array<IresMediaCmsHub>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const [modal, setModal] = useState({
    isOpen: false,
    data: null,
  });
  const [modalHistory, setModalHistory] = useState({
    isOpen: false,
    id: 0,
  });
  const [modalHistoryTraffic, setModalHistoryTraffic] = useState({
    isOpen: false,
    id: 0,
  });
  const TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS_LANG =
    TYPE_TRANSMISSION_FORMAT_MEMBERSHIP_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    }));
  const MEMBERSHIP_STATUS_LANG = MEMBERSHIP_STATUS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }))
  const [exceededUsageCount, setExceededUsageCount] = useState(0);

  // Column
  const columns = useMemo(() => {
    return [
      {
        Header: t("media.STT"),
        accessor: "id",
        sortable: false,
        Cell: ({ row }: any) => (
          <div>
            {pagination.total -
              pagination.page_size * (pagination.page - 1) -
              (row?.index || 0)}
          </div>
        ),
      },
      {
        Header: t("membership.media_name"),
        accessor: "company_name",
        sortable: false,
        thWidth: 200,
      },
      {
        Header: (
          <div>
            <p className="m-0">{t("membership.membership")}</p>
            <div className="d-flex">
              <p className="m-0" style={{ width: "50%" }}>
                {t("membership.membership_name")}
              </p>
              <p className="m-0" style={{ width: "50%" }}>
                {t("membership.status_special")}
              </p>
            </div>
          </div>
        ),
        accessor: "plan_name",
        sortable: false,
        thClass: "text-center",
        // thWidth: 300,
        Cell: ({ row }: any) => (
          <div className="d-flex gap-x-[10px]">
            <div
              style={{ width: "50%",minWidth: "150px" }}
              className="d-flex align-items-center justify-content-center gap-2 text-center"
            >
              {row?.original?.is_special  ? (
                <div
                  style={{
                    border: "0.84px solid #000",
                    backgroundColor: "#5CF300",
                    borderRadius: "839.16px",
                    fontSize: "10px",
                    padding: "4.2px 5.88px",
                    whiteSpace: "nowrap",
                  }}
                >
                  스페셜
                </div>
              ) : null}
              <p className="m-0" style={{ whiteSpace: "nowrap" }} onClick={() =>
                    setModal({
                      isOpen: true,
                      data: row?.original,
                    })
                  }>
                {row?.original?.plan_name && !row?.original?.is_trial ? (
                  <u className="cursor-pointer">{row?.original?.plan_name}</u>
                ) : (
                  "-"
                )}
              </p>
            </div>
            <div style={{ width: "50%",minWidth: "100px" }} className="text-center">
              <DropdownOption
                name="service_type"
                dataList={MEMBERSHIP_STATUS_LANG}
                className="search-filter-category-type"
                classNamePrefix="name-prefix"
                onChangeSelect={(e: any) => {
                  changeSpecialStatus(row?.original?.company_id, Number(e?.value));
                }}
                initialValue={MEMBERSHIP_STATUS_LANG.find((item: any) => item?.value === row?.original?.status_special?.toString()) || {
                  label: t('membership.not_apply'),
                  value: "0",
                }}
                labelTop={""}
                placeholder="-"
              />
            </div>
          </div>
        ),
      },
      {
        Header: (
          <div>
            <p className="m-0">{t("membership.traffic")}</p>
            <div className="d-flex">
              <p className="m-0" style={{ width: "33.33%",minWidth: "100px" }}>
                {t("membership.daily_average_PV")}
              </p>
              <p className="m-0" style={{ width: "33.33%",minWidth: "100px" }}>
                {t("membership.monthly_average_PV")}
              </p>
              <p className="m-0" style={{ width: "33.33%",minWidth: "100px" }}>
                {t("membership.special_requirements")}
              </p>
            </div>
          </div>
        ),
        accessor: "traffic",
        sortable: false,
        thClass: "text-center",
        // thWidth: 300,
        Cell: ({ row }: any) => (
          <div className="d-flex gap-x-[10px]">
            <div
              style={{ width: "33.33%",minWidth: "100px" }}
              className="d-flex align-items-center justify-content-center gap-2 text-center"
            >
              {row?.original?.is_trial ? "-" : formatNumber(row?.original?.daily_pv)}
            </div>
            <div style={{ width: "33.33%",minWidth: "100px" }} className="text-center">
              {row?.original?.is_trial ? "-" : formatNumber(row?.original?.monthly_pv)}
            </div>
            <div style={{ width: "33.33%",minWidth: "100px" }} className="text-center cursor-pointer">
              {row?.original?.is_trial ? "-" : row?.original?.special_requirement > -1 ? <u onClick={()=>{
              setModalHistoryTraffic({
                isOpen: true,
                id: row?.original?.company_id,
              })
            }}>{row?.original?.special_requirement === 0 ? t('membership.unsuitable') : t('membership.fitness')}</u> : "-"}
            </div>
          </div>
        ),
      },
      {
        Header: t("membership.usage_(Used/Total)"),
        accessor: "usage_(Used/Total)",
        sortable: false,
        Cell: ({ row }: any) => {
          const {
            is_trial,
            hdd,
            limit_hdd,
            daily_transfer,
            limit_daily_transfer,
          } = row?.original;
          return !is_trial ? (
            <div className="d-flex align-items-center gap-4">
              <div className="d-flex align-items-center gap-3">
                <span className="fw-bold">HDD</span>
                <span>
                  {hdd}GB / {limit_hdd}GB
                </span>
              </div>
              <div className="d-flex align-items-center gap-3">
                <span className="fw-bold">
                  {t("membership.daily_transfer_volume")}
                </span>
                <span>
                  {daily_transfer}GB / {limit_daily_transfer}GB
                </span>
              </div>
            </div>
          ) : (
            "-"
          );
        },
      },
      {
        Header: t("membership.payment_date"),
        accessor: "payment_date",
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => {
          return (
              <div
              style={{
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalHistory({
                  isOpen: true,
                  id: row?.original?.company_id,
                });
              }}
            >
              {renderPaymentDate(row?.original)}
            </div>
            ) ;
        },
      },
    ];
  }, [t, i18n?.language, pagination]);

  const renderPaymentDate = (value:any) => {
    if(value.is_trial){
      return "-";
    }
    if(value.is_auto_renewal){
      return <u>{t("membership.1st_of_every_month")}</u>
    } else if(!value.is_auto_renewal && !value.is_completed_cancellation) {
      return <u>{t("membership.scheduled_for_termination")}</u>
    } else if(!value.is_auto_renewal && value.is_completed_cancellation) {
      return "-";
    }
  }

  const paging = async () => {
    queryParams.start_date = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;
    queryParams.end_date = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : null;
    const [err, result] = await catchAsync(
      cmsHubMembershipApi.cmshubCompanyPlan(queryParams),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  // Search
  const handleSearchCategory = () => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: 1,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
    });
  };

  const handleChangePage = (page: any) => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: page + 1,
    });
  };

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setDateSearch([moment().subtract(7, "days").toDate(), moment().toDate()]);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const getSummaries = async () => {
    const { plan_code , ...params }= queryParams
    const [err, result] = await catchAsync(
      cmsHubMembershipApi.cmshubSummaryCompanyPlan(params),
      setLoadingSummaries
    );

    if (err) {
      console.log(err);
      return;
    }
    setSummaries(result?.data);
  };

  const getExceededUsageCount = async () => {
    const [err, result] = await catchAsync(
      cmsHubMembershipApi.cmshubCompanyExceededUsageCount()
    );

    if (err) {
      console.log(err);
      return;
    }
    setExceededUsageCount(result?.data?.total_exceeded_usage);
  };
  const changeSpecialStatus = async (company_id:number,status:number) => {
    const [err, result] = await catchAsync(
      cmsHubMembershipApi.changeSpecialStatus(company_id,status)
    );

    if (err) {
      console.log(err);
      return;
    };

    paging();
  };

  useEffect(() => {
    paging();
    getSummaries();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location.search]);

  useEffect(() => {
    getExceededUsageCount();
  }, []);

  return (
    <React.Fragment>
      <Card id="leadsList">
        <CardHeader className="border-0">
          <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
            <div className="w-100">
              <Row>
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mt-2 mt-md-0 mb-2 mb-md-0"
                >
                  <div
                    className="d-flex align-items-center date-picker-wrapper-custom"
                    style={{ gap: "40px" }}
                  >
                    <div className="fw-bold text-nowrap">
                      {t("membership.period")}
                    </div>
                    <div style={{ width: "300px" }}>
                      <DatePickerCustom
                        placeholder={`${t("A From Range")} - ${t(
                          "A To Range"
                        )}`}
                        startDate={startDate || null}
                        endDate={endDate || null}
                        disabled={false}
                        isClearable={true}
                        onChangePicker={handleChangePicker}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={12} lg={6} xl={6}>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={loading}
                      onClick={() =>
                        navigate(ROUTES.CMS_HUB_MEMBERSHIP_USAGE_EXCEEDED)
                      }
                    >
                      {t("membership.usage_exceeded")} {exceededUsageCount || 0}
                    </button>
                  </div>
                </Col>
              </Row>
              <Row className="g-4 align-items-center mt-0">
                <Col sm={12} md={12} lg={6} xl={6} className="mt-2 mt-md-3">
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "40px" }}
                  >
                    <div className="fw-bold text-nowrap">
                      {t("membership.search")}
                    </div>
                    <Input
                      type="text"
                      className="form-control search"
                      placeholder={`${t("cms_media.input_search")}.`}
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearchCategory();
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mt-3 mt-md-3 hstack gap-1 justify-content-sm-center justify-content-md-between"
                >
                  <div className="d-flex gap-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={loading}
                      onClick={handleSearchCategory}
                    >
                      <i className="ri-search-line align-bottom me-1"></i>{" "}
                      {t("Button Search")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary fs-14"
                      onClick={resetData}
                    >
                      <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                      {t("Button Reset")}
                    </button>
                  </div>
                </Col>
              </Row>
              <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2">
                <CardSummary
                  title="membership.basic"
                  value={summaries?.basic || 0}
                  isLoading={loadingSummaries}
                />

                <CardSummary
                  title="membership.standard"
                  value={summaries?.standard || 0}
                  isLoading={loadingSummaries}
                />

                <CardSummary
                  title="membership.premium"
                  value={summaries?.premium || 0}
                  isLoading={loadingSummaries}
                />

                <CardSummary
                  title="membership.enterprise"
                  value={summaries?.enterprise || 0}
                  isLoading={loadingSummaries}
                />

                <CardSummary
                  title="membership.special"
                  value={summaries?.special || 0}
                  isLoading={loadingSummaries}
                />
              </div>
            </div>
          </div>
          <Nav
            className="nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0"
            role="tablist"
          >
            {TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS_LANG.map((item, index) => (
              <NavItem key={index}>
                <NavLink
                  className={`cursor-pointer ${classnames({
                    active: queryParams?.plan_code == item?.value,
                  })}`}
                  onClick={() => {
                    setQueryParams({
                      plan_code: item?.value,
                      page:1
                    });
                  }}
                >
                  {t(item?.label)}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </CardHeader>
        <CardBody className="pt-3">
          <div>
            <TableContainer
              columns={columns}
              data={rows || []}
              customPageSize={pagination?.page_size}
              customPageIndex={pagination?.page - 1}
              totalRecords={pagination?.total}
              customPageCount={Math.ceil(
                pagination?.total / pagination?.page_size
              )}
              className="custom-header-css"
              divClass="table-responsive table-card"
              tableClass="align-middle"
              theadClass="table-light align-middle"
              handleChangePage={handleChangePage}
              isLoading={loading}
            />
          </div>
          <ToastContainer closeButton={false} limit={1} />
        </CardBody>
      </Card>
      <ModalUpdateMember
        isOpen={modal.isOpen}
        data={modal.data}
        onClose={() => setModal({ isOpen: false, data: null })}
        refresh={() => paging()}
      />
      <ModalHistory
        isOpen={modalHistory.isOpen}
        id={modalHistory.id}
        onClose={() => setModalHistory({ isOpen: false, id: 0 })}
      />
      <ModalHistoryTrafic
        isOpen={modalHistoryTraffic.isOpen}
        id={modalHistoryTraffic.id}
        onClose={() => setModalHistoryTraffic({ isOpen: false, id: 0 })}
      />
    </React.Fragment>
  );
};

export default MemberTab;
